import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Detail from "./pages/Detail";
import Search from "./pages/Search";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      Component: Dashboard,
    },
    {
      path: "/detail/:id",
      Component: Detail,
    },
    {
      path: "/search",
      Component: Search,
      children: [
        {
          path: ":search",
          Component: Search,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
