import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";

const Search = () => {
  const { search } = useParams();
  const [searchKey, setSearchKey] = useState(search);
  const [searchList, setSearchList] = useState([]);
  useEffect(() => {
    onSearchVideo(searchKey);
  }, [search]);

  const onSearchVideo = async () => {
    const request = await fetch(
      `https://emreramazanoglu.com.tr/search/?search=${searchKey}`
    );
    const response = await request.json();
    if (response.error) {
      setSearchKey("");
    }
    setSearchList(response.results);
  };
  return (
    <React.Fragment>
      <Header />
      <div className="container mt-5 p-4 card">
        <div className="form-group">
          <label>
            <b>Search Video </b>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Magiclick"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
          />
        </div>
        <button className="btn btn-dark w-100 btn-lg" onClick={onSearchVideo}>
          Search
        </button>
      </div>
      <div className="container card mt-5 p-4">
        <div className="card-columns gap-4">
          {searchList?.map((item, index) => (
            <div className="card">
              <Link to={`/detail/${item.id}`}>
                <img
                  className="card-img-top"
                  src={
                    item.thumbnails.high.url ||
                    item.thumbnails.medium.url ||
                    item.thumbnails.default.url
                  }
                  alt
                />
                <div className="card-body">
                  <h4 className="card-title">{decodeURI(item.title)}</h4>
                  <button className="btn btn-dark w-100">Download</button>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Search;
