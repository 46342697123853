import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import Select from "react-select";

const Detail = (e) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [videoDetail, setVideoDetail] = useState();
  const [selectFormat, setSelectFormat] = useState(null);
  const [textLimit, setTextLimit] = useState({ start: 0, offset: 200 });

  useEffect(() => {
    if (id.length == 0 || id.length < 9) {
      navigate("/");
    }
    getData();
  }, [id]);

  const getData = async () => {
    const request = await fetch(`https://emreramazanoglu.com.tr/?query=${id}`);
    const response = await request.json();
    if (response.error) {
      navigate("/?error=true");
    }
    setVideoDetail(response);
  };

  const textMore = () => {
    setTextLimit({
      start: 0,
      offset: videoDetail?.videoDetails?.description.length,
    });
  };

  if (videoDetail == null) {
    return "bekle 2dk amk işte ";
  }

  const filterFormatsVideos = videoDetail?.formats
    ?.sort(
      (a, b) =>
        parseInt(b?.qualityLabel?.replace("p", "")) -
        parseInt(a?.qualityLabel?.replace("p", ""))
    )
    .map((res) => ({
      ...res,
      label: `${res.mimeType.split(";")[0]} ${res.qualityLabel}`,
      value: res.url,
    }));

  const filterFormatsAudio = videoDetail.formats
    .filter((filter) => filter.hasAudio == true && filter.qualityLabel != null)
    .map((res) => ({
      ...res,
      label: `${res.mimeType.split(";")[0]} ${res.qualityLabel}`,
      value: res.url,
    }));
  const groupedFormat = [
    {
      label: "Videos",
      options: filterFormatsVideos,
    },
    {
      label: "Audios",
      options: filterFormatsAudio,
    },
  ];

  const formatGroupLabel = (data) => (
    <div>
      <span>{data.label}</span>
      <span>{data.options.length}</span>
    </div>
  );

  const handleSelectFormat = (e) => {
    setSelectFormat(e);
  };

  const handleDownload = (e) => {
    window.open(
      `${selectFormat?.url}&amp;title=${encodeURI(
        videoDetail?.videoDetails?.title
      )}`,
      "_blank"
    );
  };

  return (
    <React.Fragment>
      <Header />

      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-8">
            <div className="card bg-white">
              <iframe
                className="card-img-top"
                style={{ height: 500 }}
                src={videoDetail?.videoDetails?.embed?.iframeUrl}
                frameborder="0"
              ></iframe>
              <div className="card-body">
                <h4 className="card-title">
                  {videoDetail?.videoDetails?.title}
                </h4>

                <div class="form-group">
                  <label for="format">
                    <b>Format</b>
                  </label>
                  <Select
                    defaultValue={selectFormat}
                    options={groupedFormat}
                    formatGroupLabel={formatGroupLabel}
                    onChange={handleSelectFormat}
                  />
                </div>

                <button
                  onClick={handleDownload}
                  title={videoDetail?.videoDetails?.title}
                  id="download-720-MP4"
                  class="start-button d-button disabled"
                  role="button"
                  download={true}
                  disabled={!selectFormat?.url}
                  aria-pressed="true"
                  className="btn btn-dark w-100"
                >
                  Download
                </button>
                <hr />
                <p className="card-text">
                  {videoDetail?.videoDetails?.description &&
                    videoDetail?.videoDetails?.description?.slice(
                      textLimit.start,
                      textLimit.offset
                    )}
                  {textLimit.offset < 200 && "..."}
                </p>
                {videoDetail?.videoDetails?.description &&
                  textLimit.offset < 201 && (
                    <button className="btn btn-dark w-100" onClick={textMore}>
                      daha fazla göster
                    </button>
                  )}
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            {videoDetail?.related_videos?.map((video, index) => (
              <div className="card mb-4">
                <Link to={`/detail/${video.id}`}>
                  <img
                    className="card-img-top"
                    src={video.thumbnails[1].url}
                    key={index}
                  />
                  <div className="card-body">
                    <h4 className="card-title text-dark">{video.title}</h4>
                    <button className="btn btn-dark w-100">Download</button>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Detail;
