import React, { useState } from "react";
import Header from "../components/Header";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  const converterVideo = () => {
    if (url.length != 0 && url.length > 20) {
      let id = url.split("?v=")[1];
      navigate(`/detail/${id}`);
    } else {
      Swal.fire({
        title: "Lütfen Kontrol Edin",
        text: "be Amına koduğum böyle link mi olur senin kafanı sikeyim",
        icon: "error",
        confirmButtonText: "kapat amına koyim",
      });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="container mt-5">
        <div className="form-group">
          <label>
            <b>Video Url {url}</b>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="https://www.youtube.com/watch?v=jqLb4_27bqE"
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <button className="btn btn-dark w-100 btn-lg" onClick={converterVideo}>
          Converter
        </button>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
